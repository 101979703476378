<template>
  <div>
    <v-layout class="mainfont" wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 v-if="list.length > 0">
            <v-layout wrap pt-7 justify-center>
              <v-flex xs6>
                <v-card elevation="0" class="pa-1" style="border-radius: 0px">
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                color="red"
                                class="mainfont"
                                style="
                                  background-color: #13736f;
                                  color: white;
                                  font-size: 15px;
                                  font-weight: lighter;
                                "
                              >
                                Name
                              </th>
                              <th
                                class="mainfont"
                                style="
                                  background-color: #13736f;
                                  color: white;
                                  font-size: 15px;
                                  font-weight: lighter;
                                "
                              >
                                Role
                              </th>

                              <th
                                class="mainfont"
                                style="
                                  background-color: #13736f;
                                  color: white;
                                  font-size: 15px;
                                  font-weight: lighter;
                                "
                              >
                                Date & Time
                              </th>
                              <th
                                class="mainfont"
                                style="
                                  background-color: #13736f;
                                  color: white;
                                  font-size: 15px;
                                  font-weight: lighter;
                                "
                              >
                                Bid Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, i) in list"
                              :key="i"
                              style="cursor: pointer"
                            >
                              <td v-if="item.uid.role === 'Trader'">
                                {{ item.uid.traderId.name }}
                              </td>

                              <td v-else-if="item.uid.role === 'Customer'">
                                {{ item.uid.customerId.name }}
                              </td>
                              <td v-else-if="item.uid.role === 'Farmer'">
                                {{ item.uid.farmerId.name }}
                              </td>
                              <td>{{ item.uid.role }}</td>

                              <td>
                                {{ formatDate(item.create_date) }},
                                {{ formatTime(item.update_date) }}
                              </td>

                              <td>
                                <span>{{ item.bidAmount }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mainbg2" pt-4 pb-12 wrap justify-center>
                    <v-flex align-self-center>
                      <div>
                        <v-pagination
                          prev-icon="mdi-menu-left"
                          next-icon="mdi-menu-right"
                          :length="pages"
                          :total-visible="7"
                          v-model="currentPage"
                          color="#13736f"
                        ></v-pagination>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-else text-center pt-6>
            <span
              class="mainfont"
              style="color: black; font-size: 20px; font-weight: bold"
              >No Bids Currently !</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
      
      
      <script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      preview: null,
      link: null,
      page: 1,
      editdailog: false,
      currentPage: 1,

      currentpage: 1,
      showSnackBar: false,
      msg: null,
      url: null,
      categoryName: null,
      id: this.$route.query.id,

      appLoading: false,
      name: null,
      description: null,
      duration: null,
      pages: 0,
      icon: null,
      formData: new FormData(),

      listingOrder: null,
      list: [],
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/live/lot/bids/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.id,
          page: this.currentPage,
          limit: 10,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
      
      
      <style scoped>
.flex-red {
  background-color: #13736f;
}

.gx {
  background-color: red;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.justified-text {
  text-align: justify;
}
.mainbg4 {
  background-image: linear-gradient(269.6deg, #fbfbfb -31.66%, #fbfbfb);
}
</style>